type AirtableImage = {
	id: string;
	width: string;
	height: string;
	url: string;
	filename: string;
	size: number;
	type: string;

}

export type AirtableExperience = {
	id: string;
	Description: string;
	Skills: string[];
	Type: string[];
	Affiliation: string;
	Location: string;
	Image: AirtableImage[];
	Role: string;
	ShortTagline: string;
	Current: boolean;
	Link: string;
	TimePeriod: string;
	JSONLinks: string;
}

export type AirtableData = {
	experiences: AirtableExperience[]
}

export const convertExpLinksToJSON = (exp: AirtableExperience) => {
	if (!exp.JSONLinks) {
		return null;
	}
	try {
		return JSON.parse(exp.JSONLinks);
	} catch {
		return null;
	}
}