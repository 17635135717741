import React, { useEffect, useState } from 'react'
import IconText from "./IconText";
import Nav from "./Nav";

const SMALL_THRESHOLD = 680;

const Header = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => { setWidth(window.innerWidth) };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    }
  }, []);

  return (
    <div>
      <div className='m-4 p-4'>
        <figure className="image is-128x128">
          <img src={`${process.env.PUBLIC_URL}/images/nathan.jpeg`} alt="nathan in barcelona" />
        </figure>

        <div className="pt-3 columns" style={{ display: "flex", maxWidth: "112px" }}>
          <div className="column"><IconText iconSize="large" faIconName="fa-github" href="https://github.com/nathandalal" inline/></div>
          <div className="column"><IconText iconSize="large" faIconName="fa-linkedin" href="https://linkedin.com/in/nathandalal" inline/></div>
          <div className="column"><IconText iconSize="large" faIconName="fa-medium" href="https://medium.com/@nathanhdalal" inline/></div>
        </div>
        <h5 className="is-size-4">nathan dalal</h5>

        <div>
          <IconText text="ai & education @ stanford" faIconName="fa-briefcase" />
          <IconText text="san francisco" faIconName="fa-map-marker" />
          <IconText text="english / español / ગુજરાતી" faIconName="fa-globe" />
        </div>
      </div>

      <Nav isSmall={width < SMALL_THRESHOLD} />
    </div>
  );
};

export default Header;
