import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import ExperienceRow from "./ExperienceRow";
import { AirtableExperience } from "../data/airtableDataType";

type ContentPropsType = {
  list: AirtableExperience[];
};

const Content = ({ list }: ContentPropsType) => {
  const location = useLocation();
  const [expandedId, setExpandedId] = useState("");

  if (list.length === 0) return (
    <div>
      <h6>Loading</h6>
      <span className="icon"><i className="fa fa-refresh fa-spin"></i></span>
    </div>
  )

  const topic = location?.pathname.substr(1)
  const sublist = list.filter(card => topic ? card?.Type?.includes(topic) : card.Current)

  return (
    <div className="m-4 p-4 columns is-multiline">
      {sublist.map((exp, idx) => (
        <ExperienceRow 
          key={exp.Affiliation}
          exp={exp}
          showMore={expandedId === exp.id}
          onChangeShowMore={() => setExpandedId(expandedId === exp.id ? "" : exp.id)} />
        )
      )}
    </div>
  );
}

export default Content;
