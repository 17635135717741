import React from 'react';
import ReactDOM from 'react-dom/client';
import "./index.css"

import { HashRouter, Routes, Route } from 'react-router-dom'

import Layout from "./Layout";
import Content from './components/Content';

import airtableData from "./data/airtableData.json";
import { AirtableData } from "./data/airtableDataType";

const experiencesList = (airtableData as unknown as AirtableData).experiences;

const NoPage = () => (
  <div className="content">
    <span>Either this site doesn't work, or you're on the wrong URL!</span>
  </div>
)

export default function Index() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Content list={experiencesList} />} />
          <Route path="software" element={<Content list={experiencesList} />} />
          <Route path="teaching" element={<Content list={experiencesList} />} />
          <Route path="fun" element={<Content list={experiencesList} />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>
);
