import React from "react";

import { AirtableExperience, convertExpLinksToJSON } from "../data/airtableDataType";

type ExperienceRowPropsType = {
  exp: AirtableExperience;
  showMore: boolean;
  onChangeShowMore: () => void;
};

const ExperienceRow = ({ exp, showMore, onChangeShowMore }: ExperienceRowPropsType) => {
	const linksJSON = convertExpLinksToJSON(exp);
	const displayShowMoreOption = exp.Description || linksJSON;

	console.log(exp.Image?.[0])

	return (
		<>
			<div className="column is-11 py-1" style={{ display: "flex", alignItems: "center" }} onClick={onChangeShowMore}>
				<div style={{ minWidth: "50px" }}>
					{displayShowMoreOption && <span className={`fa ${showMore ? "fa-minus" : "fa-plus"} pr-5`} />}
				</div>
				<div>
					<h3 className="is-size-5 is-lowercase">
						<span>{exp.Role} / </span>
						{
							exp.Link
							? <b className="is-size-4" style={{cursor: "pointer"}}>
									<a href={exp.Link} target="_blank" rel="noreferrer" style={{ color: "black" }}>
										{exp.Affiliation}
									</a>
								</b>
							: <b className="is-size-4">{exp.Affiliation}</b>}
						<span className="is-size-5"> / {exp.ShortTagline}</span>
					</h3>
					<h3 className="is-size-6 is-lowercase">
						<span>{exp.TimePeriod}</span>
						<span className="is-size-7"> in {exp.Location}</span>
					</h3>
				</div>
			</div>
			{showMore && (
				<div className="column is-11 py-2">
					{ /* Image links expire after 2 hours, so this block is not usable. */ }
					{ /* {exp.Image?.[0] && (
						<img
							src={exp.Image?.[0].url}
							alt={exp.Image?.[0].filename}
							width={exp.Image?.[0].width}
							height={exp.Image?.[0].height}
							style={{ maxWidth: "150px", maxHeight: "500px" }}
						/>
					)} */ }
					<h3 className="is-size-5 py-2">
						<span style={{ whiteSpace: "pre-wrap" }}>{exp.Description}</span>
					</h3>
					{linksJSON && (
					<div className="py-1 px-3">
						<h5>External Links:</h5>
						<ul style={{ listStyleType: "disc" }}>
							{Object.keys(linksJSON).map((key) => (
								<li className="mx-4"><a target="_blank" rel="noreferrer" href={linksJSON[key]}>{key}</a></li>
							))}
						</ul>
					</div>
					)}
				</div>
			)}
		</>
	);
};

export default ExperienceRow;
