import React from 'react'
import { Link, useLocation } from 'react-router-dom'

export const navData = [
  { path: "/",            name: "highlighted experiences",               smallName: "now",  icon: "calendar-day"},
  { path: "/software",    name: "technology work",               smallName: "tech",              icon: "laptop"},
  { path: "/teaching",    name: "teaching & education",     smallName: "education", icon: "book"},
  { path: "/fun",         name: "recreation",               smallName: "fun", icon: "dribbble"}
];

const Nav = ({ isSmall }: { isSmall?: boolean }) => {
  const location = useLocation();
  return (
    <div className={`tabs is-boxed is-left ${isSmall ? "is-small" : ""}`} style={{ marginBottom: 0 }}>
      <ul>{navData.map(d => (
        <li className={d.path === location?.pathname ? "is-active" : ""} key={d.path}>
          <Link to={d.path}>
            <span className="icon is-small">
              <i className={`fa fa-${d.icon}`}/>
            </span>
            <span>{isSmall ? d.smallName : d.name}</span>
          </Link>
        </li>
      ))}</ul>
    </div>
  );
};

export default Nav;
