import React from 'react'

const Footer = () => (
  <footer className="hero-foot footer pb-6">
    <div className='mx-4 px-4'>
      <p className="title">
        learning is everything.
      </p>
      <p className="subtitle is-6 is-pulled-right">
        this website is designed by{" "}
        <strong>nathan</strong>{" "}
        with the help of
        {" "}<a href="https://facebook.github.io/react/">React</a>
        {" "}and
        {" "}<a href="http://bulma.io/">Bulma</a>.
      </p>
    </div>
  </footer>
)

export default Footer