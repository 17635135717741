import React from "react";
import { Link } from 'react-router-dom';

type IconTextProps = {
	faIconName: string;
	iconSize?: "small" | "large"
	text?: string;
	href?: string;
	inline?: boolean;
}

const IconText = ({
	text,
	iconSize = "small",
	faIconName,
	href,
	inline,
}: IconTextProps) => {
	const element = (
		<div style={{ ...inline && { display: "inline-block" } }}>
	    <span style={{ display: "inline-block", minWidth: "30px" }}>
	      <i className={`icon fa ${faIconName} ${iconSize === "large" ? "fa-xl" : "fa-sm"}`}/>
	    </span>
	    <span>{text || ""}</span>
    </div>
	);
	if (!href) {
		return element;
	}
	return <Link to={href}>{element}</Link>;
};

export default IconText;