import React from 'react';
import { Outlet } from "react-router-dom";

import Header from './components/Header'
import Footer from './components/Footer'

const Layout = (): JSX.Element => (
  <section style={{ minHeight: "100vh" }}>
		<Header />
    <Outlet />
		<Footer />
  </section>
);

export default Layout;
